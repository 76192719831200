import React, { useState, useEffect } from "react";
import WebinarForm from "@/components/Forms/WebinarForm";
import WebinarPointers from "./WebinarPointers";
import WebinarBenefits from "./WebinarBenefits";
import WebinarRules from "./WebinarRules";
import WebinarHero from "./WebinarHero";
import { useParams } from "react-router-dom";
import { baseURL } from "@/utility/api";
import axios from "axios";
import CohortForm from "@/components/Forms/CohortForm";

const WebinarPage = () => {
  const { id } = useParams();
  
  const [details, setDetails] = useState();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  

  useEffect(() => {
    async function fetchData() {
      const response = await axios.post(`${baseURL}/webinar/get-details`, {
        id,
      });
      setDetails(response.data);
    }
    fetchData();
  }, [id]);

  return (
    <div className="min-h-screen bg-black text-white font-inter">
      {/* Hero Section */}
      <WebinarHero details={details} WebinarForm={WebinarForm} />

      {/* Webinar Pointers Section */}
      <WebinarPointers />

      {/* Additional Benefits Section */}
      <WebinarBenefits />

      {/* Rules Section */}
      <WebinarRules />
    </div>
  );
};

export default WebinarPage;
