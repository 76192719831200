// import { Mail, Phone, User } from 'lucide-react';
// import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Checkout from '../PaymentGateway/Checkout';

// const WebinarForm = ({ price,canBook }) => {
//   const { id } = useParams();
//   const [info, setInfo] = useState({
//     id: id,
//     name: '',
//     email: '',
//     phone_number: '',
//     type: 'webinar',
//   });

//   const handleInputChange = (e, field) => {
//     let value = e.target.value;

//     if (field === "phone_number") {
//       value = value.replace(/\D/g, "");
//     }

//     setInfo({
//       ...info,
//       [field]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form submitted:", info);
//   };

//   return (
//     <form onSubmit={handleSubmit} className="space-y-6">

//       { canBook && [
//         { icon: <User />, label: "Name", type: "text", placeholder: "Enter your name", value: "name", autoComplete: "name" },
//         { icon: <Mail />, label: "Email", type: "email", placeholder: "Enter your email", value: "email", autoComplete: "email" },
//         { icon: <Phone />, label: "Phone Number", type: "tel", placeholder: "Enter your phone number", value: "phone_number", autoComplete: "tel", inputMode: "numeric" },
//       ].map((field, index) => (
//         <div key={index}>
//           <label className="block text-sm font-medium mb-2 text-gray-400">{field.label}</label>
//           <div className="relative group">
//             <div className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 group-hover:text-purple-400 transition-colors duration-300">
//               {field.icon}
//             </div>
//             <input
//               type={field.type}
//               className="w-full bg-gray-900/50 rounded-xl pl-12 pr-4 py-3 text-white placeholder-gray-500 border border-gray-800 focus:border-purple-500 focus:ring-2 focus:ring-purple-500/50 focus:outline-none transition-all duration-300 hover:bg-gray-800/80 group-hover:border-purple-500/50"
//               placeholder={field.placeholder}
//               value={info[field.value]}
//               onChange={(e) => handleInputChange(e, field.value)}
//               pattern={field.value === "phone_number" ? "[0-9]{10}" : undefined}
//               maxLength={field.value === "phone_number" ? 10 : undefined}
//               required
//               autoComplete={field.autoComplete}
//               inputMode={field.inputMode}
//             />
//           </div>
//         </div>
//       ))}

//     {canBook &&
//       <div className="flex items-center justify-between bg-gray-800 p-4 rounded-xl">
//         <div className="flex items-center space-x-2 text-white">
//           <span className="text-lg font-medium">Price: ₹{price}</span>
//         </div>
//       </div>
// }

//       {!canBook ?

//       <div className="w-full bg-gradient-to-r from-blue-400 to-purple-600 hover:from-blue-500 hover:to-purple-700 text-white font-medium py-4 rounded-xl transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-purple-500/20 text-center" >Webinar Full</div> :

//       <Checkout info={info} title={"Confirm and Pay"} className="w-full bg-gradient-to-r from-blue-400 to-purple-600 hover:from-blue-500 hover:to-purple-700 text-white font-medium py-4 rounded-xl transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-purple-500/20" />}
//     </form>
//   );
// };

// export default WebinarForm;

import React, { useState } from "react";
import { Mail, Phone, Timer, User, CheckCircle, XCircle, Calendar, Clock, Users } from "lucide-react";
import Checkout from "../PaymentGateway/Checkout";

const WebinarForm = ({ price, canBook , seats_left ,date = "April 20th, 2025", day = "Sunday", time = "4:00 PM", timezone = "IST", capacity = "100"}) => {
  const [info, setInfo] = useState({
    id: 102,
    name: '',
    email: '',
    phone_number: '',
    type: 'webinar',
  });

  const handleInputChange = (e, field) => {
    let value = e.target.value;

    if (field === "phone_number") {
      value = value.replace(/\D/g, "");
    }

    setInfo({
      ...info,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
  };

  const formFields = [
    {
      icon: <User className="w-4 h-4" />,
      label: "Full Name",
      type: "text",
      placeholder: "Enter your full name",
      value: "name",
      autoComplete: "name",
    },
    {
      icon: <Mail className="w-4 h-4" />,
      label: "Email Address",
      type: "email",
      placeholder: "Enter your email",
      value: "email",
      autoComplete: "email",
    },
    {
      icon: <Phone className="w-4 h-4" />,
      label: "Phone Number",
      type: "tel",
      placeholder: "Enter 10-digit mobile number",
      value: "phone_number",
      autoComplete: "tel",
      pattern: "[0-9]{10}",
      maxLength: 10,
    },
  ];

  return (
    <div className="bg-[#0A0A1A] border border-gray-800/50 rounded-2xl shadow-2xl shadow-gray-900/30 overflow-hidden">
      {/* Header */}
      <div className="bg-gray-900/10 border-b border-gray-800/30 p-4 text-center">
        <div
          className="inline-flex items-center gap-2 px-3 py-1 rounded-full 
          bg-purple-500/10 border border-purple-500/30 mb-2"
        >
          <Timer className="w-3 h-3 text-purple-400 animate-pulse" />
          <span className="text-xs font-semibold text-purple-400 tracking-wide uppercase">
            Limited Seats Available
          </span>
        </div>
        <h2 className="text-xl font-bold text-white">Secure Your Spot Now</h2>
      </div>

      <div className="flex flex-row justify-between items-center p-4 rounded-lg border border-indigo-500/30 bg-black/60 backdrop-blur-sm  mx-3">
        <div className="flex-1 text-center">
          <div className="flex flex-col items-center gap-1">
            <div className="p-1.5 rounded-lg bg-indigo-500/10">
              <Calendar className="w-4 h-4 text-indigo-400" />
            </div>
            <div className="text-sm font-bold text-white">{date}</div>
            <div className="text-md font-bold text-indigo-400">{day}</div>
          </div>
        </div>
        <div className="flex-1 text-center">
          <div className="flex flex-col items-center gap-1">
            <div className="p-1.5 rounded-lg bg-indigo-500/10">
              <Clock className="w-4 h-4 text-indigo-400" />
            </div>
            <div className="text-sm font-bold text-white">{time}</div>
            <div className="text-md font-bold text-indigo-400">{timezone}</div>
          </div>
        </div>
        <div className="flex-1 text-center">
          <div className="flex flex-col items-center gap-1">
            <div className="p-1.5 rounded-lg bg-indigo-500/10">
              <Users className="w-4 h-4 text-indigo-400" />
            </div>
            <div className="text-sm font-bold text-white">Seats Left</div>
            <div className="text-md font-bold text-indigo-400">{seats_left}</div>
          </div>
        </div>
      </div>


      {/* Form Content */}
      <div className="p-4 sm:p-6">
        <form onSubmit={handleSubmit} className="space-y-4">
          {canBook ? (
            <>
              {formFields.map((field, index) => (
                <div key={index} className="space-y-1">
                  <label className="block text-xs font-medium text-gray-400">
                    {field.label}
                  </label>
                  <div className="relative group">
                    <div
                      className="absolute left-3 top-1/2 -translate-y-1/2 
                      text-gray-500 group-hover:text-indigo-400 
                      transition-colors duration-300"
                    >
                      {field.icon}
                    </div>
                    <input
                      type={field.type}
                      className="w-full bg-[#0F1129] rounded-xl pl-9 pr-3 py-2 
                        text-white placeholder-gray-600 border border-gray-800/50
                        focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/50 
                        focus:outline-none transition-all duration-300 text-sm"
                      placeholder={field.placeholder}
                      value={info[field.value]}
                      onChange={(e) => handleInputChange(e, field.value)}
                      pattern={field.pattern}
                      maxLength={field.maxLength}
                      required
                      autoComplete={field.autoComplete}
                    />
                  </div>
                </div>
              ))}

              {/* Price Section */}
              <div
                className="bg-[#0F1129] p-3 rounded-xl border border-gray-800/50 
                flex items-center justify-between"
              >
                <div className="space-y-0.5">
                  <span className="text-xs text-gray-400">Total Price</span>
                  <div className="flex items-baseline space-x-1">
                    <span className="text-xl font-bold text-white">
                      ₹{price.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div
                  className="bg-emerald-500/10 border border-emerald-500/30 
                  px-2 py-0.5 rounded-lg"
                >
                  <span className="text-emerald-400 text-xs font-medium">
                    Taxes Included
                  </span>
                </div>
              </div>

              {/* Submit Button */}
              <Checkout
              info = {info}
           
                className="w-full bg-gradient-to-r from-indigo-600 to-purple-700 
                  text-white font-semibold py-3 rounded-xl 
                  hover:from-indigo-700 hover:to-purple-800 
                  transition-all duration-300 
                  transform hover:scale-105 hover:shadow-2xl 
                  hover:shadow-purple-500/30 
                  flex items-center justify-center gap-2 text-sm"
                  title={"Confirm and Pay"}
              />
                
              
            </>
          ) : (
            <div className="text-center space-y-3">
              <XCircle className="w-12 h-12 mx-auto text-red-400" />
              <h3 className="text-lg font-semibold text-white">
                Webinar is Full
              </h3>
              <p className="text-xs text-gray-400">
                Sorry, all seats have been booked for this session.
              </p>
            </div>
          )}
        </form>

        {/* Seats Left Indicator */}
        
      </div>
    </div>
  );
};

export default WebinarForm;
