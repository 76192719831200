import React from "react";
import { Rocket } from "lucide-react";

const WebinarHero = ({ details, WebinarForm }) => {
  return (
    <section className="relative w-full min-h-screen flex items-center justify-center bg-black overflow-hidden">
      {/* Background Gradients */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/10 via-black to-black opacity-90" />
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,rgba(128,128,128,0.1),transparent_50%)]" />

      {/* Content Container */}
      <div className="relative z-10 w-full max-w-7xl mx-auto px-4 py-16 lg:py-24">
        <div className="grid lg:grid-cols-2 gap-12 lg:gap-16 items-center">
          {/* Left Column - Text Content */}
          <div className="text-center lg:text-left space-y-6 lg:space-y-8">
            {/* Promo Tag */}
            {/* <div
              className="inline-flex items-center gap-2 px-4 py-2 rounded-full 
              bg-purple-500/10 border border-purple-500/30 backdrop-blur-sm"
            >
              <div className="relative">
                <div className="absolute -inset-0.5 bg-purple-500 rounded-full animate-ping opacity-40" />
                <Rocket className="w-4 h-4 text-purple-400 relative" />
              </div>
              <span className="text-sm font-semibold text-purple-400 tracking-wide uppercase">
                🔥 Use code WEB50 and get ₹50 off!
              </span>
            </div> */}

            {/* Main Headline */}
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
              <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
                Crack Off-Campus
              </span>
              <br />
              <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
                Summer Internships!
              </span>
            </h1>

            {/* Divider */}
            <div className="w-24 h-1 bg-gradient-to-r from-gray-600 to-gray-400 rounded-full mx-auto lg:mx-0" />

            {/* Subheadline */}
            <p className="text-lg text-gray-400 max-w-2xl mx-auto lg:mx-0">
              Master the art of landing top internships, even with zero industry
              connections! Join our exclusive webinar and unlock strategies that{" "}
              <span className="text-white font-semibold">
                90% of students don't know
              </span>
              .
            </p>
          </div>

          {/* Right Column - Form */}
          <div className="w-full max-w-md mx-auto lg:max-w-lg xl:max-w-md">
            <WebinarForm
              price={details?.price}
              canBook={details?.canBook}
              seats_left={details?.seats_left}
            />
          </div>
        </div>
      </div>

      {/* Bottom Gradient Overlay */}
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-black to-transparent" />
    </section>
  );
};

export default WebinarHero;
