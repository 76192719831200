import React from "react";
import { motion } from "framer-motion";
import {
  Calendar,
  Clock,
  Users,
  Sparkles,
  BadgeIndianRupee,
  Target,
  Focus,
  Crosshair,
} from "lucide-react";

const WebinarBanner = () => {
  const statsData = [
    {
      Icon: Calendar,
      label: "Date",
      value: "April 20th, 2025",
      subtext: "Sunday",
    },
    {
      Icon: Clock,
      label: "Time",
      value: "4 PM",
      subtext: "IST",
    },
    {
      Icon: Users,
      label: "Capacity",
      value: "100",
      subtext: "seats",
    },
  ];

  return (
    <section className="relative w-full min-h-screen md:min-h-[90vh] pt-20 sm:pt-24 md:pt-32 pb-24 md:pb-32 overflow-hidden bg-black">
      {/* Background gradients */}
      <div className="absolute inset-0 bg-gradient-to-b from-indigo-900/1 via-black to-black" />
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,rgba(99,102,241,0.1),transparent_50%)]" />

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="space-y-12 md:space-y-12">
          {/* Status Tags */}
          <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-3">
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              className="px-4 sm:px-5 py-2 sm:py-2.5 rounded-full bg-indigo-500/10 border border-indigo-500/30 backdrop-blur-sm"
            >
              <div className="flex items-center gap-2.5">
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-indigo-500 rounded-full animate-ping opacity-40" />
                  <Sparkles className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-400 relative" />
                </div>
                <span className="text-sm sm:text-base font-semibold text-indigo-400 tracking-wide uppercase">
                  LIVE WEBINAR
                </span>
              </div>
            </motion.div>
          </div>

          {/* Main Content */}
          <div className="space-y-6 md:space-y-6 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="space-y-4 md:space-y-6"
            >
              <h1
                className="text-3xl sm:text-4xl lg:text-6xl font-bold"
                style={{ lineHeight: "1.19" }}
              >
                <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
                  Crack Off-Campus
                </span>
                <br />
                <span className="bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
                  Summer Internships
                </span>
              </h1>

              <motion.div
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ duration: 0.8, delay: 0.3 }}
                className="w-16 sm:w-24 h-1 mx-auto bg-gradient-to-r from-white to-gray-500 rounded-full"
              />
            </motion.div>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-lg sm:text-xl text-gray-400 max-w-2xl mx-auto px-4"
            >
              Master the art of landing off-campus internships this summer – so
              you’re not crying during placements!
            </motion.p>

            {/* Stats Cards - Horizontal on mobile, Grid on larger screens */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="flex flex-col md:flex-row justify-center items-stretch gap-3 md:gap-4 max-w-4xl mx-auto px-4"
            >
              {/* Mobile Stats Summary */}
              <div className="md:hidden grid grid-cols-3 gap-4 p-4 rounded-lg border border-indigo-500/30 bg-black backdrop-blur-sm">
                {statsData.map((stat, index) => (
                  <div
                    key={stat.label}
                    className="flex flex-col items-center text-center"
                  >
                    {/* Icons row */}
                    <div className="p-1.5 rounded-lg bg-indigo-500/10 mb-3">
                      <stat.Icon className="w-4 h-4 text-indigo-400" />
                    </div>

                    {/* Labels row - fixed height */}
                    <div className="text-xs text-gray-400 h-10 flex items-center justify-center px-1">
                      {stat.label}
                    </div>

                    {/* Values row - fixed height with absolute positioning */}
                    <div className="relative w-full h-10 mb-2 mt-4 md:mt-0">
                      <div className="absolute inset-0 flex items-center justify-center">
                        <span className="text-sm font-bold text-white">
                          {stat.value}
                        </span>
                      </div>
                    </div>

                    {/* Subtext row - fixed position */}
                    <div className="text-xs text-indigo-400">
                      {stat?.subtext}
                    </div>
                  </div>
                ))}
              </div>

              {/* Desktop Cards */}
              {statsData.map((stat, index) => (
                <motion.div
                  key={stat.label}
                  whileHover={{ y: -2 }}
                  className="hidden md:block relative group flex-1 min-w-[240px] max-w-[280px]"
                >
                  <div
                    className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-blue-500 rounded-lg blur opacity-30 
                                 group-hover:opacity-50 transition duration-300"
                  />
                  <div
                    className="relative h-full px-6 py-5 bg-black border border-indigo-500/30 rounded-lg backdrop-blur-sm
                                 transition-all duration-300 group-hover:border-indigo-400"
                  >
                    <div className="flex items-center justify-between mb-4">
                      <span className="text-sm font-medium text-gray-400">
                        {stat.label}
                      </span>
                      <div className="p-2 rounded-lg bg-indigo-500/10">
                        <stat.Icon className="w-4 h-4 text-indigo-400" />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="text-2xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
                        {stat.value}
                      </div>
                      <div className="text-sm font-medium text-indigo-400">
                        {stat.subtext}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>

            {/* CTA Button */}
            <motion.a
              href="/webinar/102"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.97 }}
              className="group relative inline-block overflow-hidden rounded-xl sm:rounded-2xl bg-gradient-to-r from-blue-600 via-violet-600 to-indigo-600 p-[2px] shadow-xl mt-12 md:mt-16"
            >
              <div
                className="relative flex items-center gap-2 px-6 sm:px-8 py-3 sm:py-4 rounded-xl sm:rounded-2xl bg-neutral-900 transition-all duration-300
                             group-hover:bg-opacity-80"
              >
                <span className="relative text-base sm:text-lg font-semibold text-white tracking-wide">
                  Know More
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="w-4 h-4 sm:w-5 sm:h-5 text-white transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 12h14M12 5l7 7-7 7"
                  />
                </svg>
              </div>

              <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-500 opacity-0 group-hover:opacity-30 transition-opacity duration-500 blur-2xl" />
            </motion.a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebinarBanner;
